import React, { Component } from 'react';
import './GiftCard.css'
import giftcardImg1 from '../../assets/giftcard1.jpeg'
import giftcardImg2 from '../../assets/giftcard2.jpeg'
import giftcardImg3 from '../../assets/giftcard3.jpeg'
import giftcardImg4 from '../../assets/giftcard4.jpeg'

class GiftCard extends Component {

    
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    
    handleWindowSizeChange = () => {
        this.setState({ windowWidth: window.innerWidth });
    };
    render() {
        return ( 
            <div className='gc-body'>
                <h1>ПОДАРОЧНЫЕ КАРТЫ</h1>
                <div className='gc-text'>
                    <p>Идеальный подарок для тех, кто ценит красоту и заботу.</p>
                </div>
                <div className='gc-images'>
                    <div className='gc-item1'><img className='gc-img' src={giftcardImg1}/></div>
                    <div className='gc-item2'><img className='gc-img' src={giftcardImg2}/></div>
                    <div className='gc-item3'><img className='gc-img' src={giftcardImg3}/></div>
                    <div className='gc-item4'><img className='gc-img' src={giftcardImg4}/></div>
                </div>
                <div className='gc-info'>
                    <a>*подробную информацию уточняйте у администратора*</a>
                </div>
            </div>
        );
    }
}

  export default GiftCard;